/* eslint-env browser */
/* eslint-disable complexity */
import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import {parseVersionFromFileName} from "../../components/common/FirmwareParsing.js";
import Alert from "../../components/primitives/Alert.jsx";
import {deviceForwardingModes} from "../../components/primitives/ForwardingModes.jsx";

function DeviceActionsConfirmationMessages({
  // Props
  watchAllFields,
  checkedDevices,
  devicesToReceiveAction,
  totalDevices,
  multipleDevices,
  facilities,
  userFriendlyTypeNames,
}) {
  // ---------------------------------------------------------------------------
  // Helper Functions
  // ---------------------------------------------------------------------------
  const getUpdatedForwardingMode = React.useCallback(
    (checkedDevice) => {
      const foundFacility = facilities.find((aFacility) => checkedDevices[0].facilityId === aFacility.id);
      const facilityForwardingMode = deviceForwardingModes[foundFacility.forwardingMode].name;
      const facilityForwardingModeAndUrl =
        foundFacility.forwardingMode !== "inboxForwarding"
          ? `${deviceForwardingModes[foundFacility.forwardingMode].name}: ${
              foundFacility.deviceForwardingUrl
            }`
          : deviceForwardingModes[foundFacility.forwardingMode].name;

      let leftHandSide = deviceForwardingModes[checkedDevice.forwardingMode].name;
      if (checkedDevice.forwardingMode === "useDefaultModeAndAddress") {
        leftHandSide += ` (${facilityForwardingModeAndUrl})`;
      } else if (checkedDevice.forwardingMode === "useDefaultModeOnly") {
        leftHandSide += ` (${facilityForwardingMode})`;
      }
      if (
        checkedDevice.forwardingMode === "nativeForwarding" ||
        checkedDevice.forwardingMode === "useDefaultModeOnly"
      ) {
        leftHandSide += `: ${checkedDevice.forwardingUrl}`;
      }

      const {deviceTypeId} = checkedDevice;
      const selectedForwardingMode = watchAllFields[`forwardingMode-${deviceTypeId}`];
      const selectedForwardingUrl = watchAllFields[`forwardingUrl-${deviceTypeId}`];

      let rightHandSide = deviceForwardingModes[selectedForwardingMode].name;
      if (selectedForwardingMode === "useDefaultModeAndAddress") {
        rightHandSide += ` (${facilityForwardingModeAndUrl})`;
      } else if (selectedForwardingMode === "useDefaultModeOnly") {
        rightHandSide += ` (${facilityForwardingMode})`;
      }
      if (selectedForwardingMode === "nativeForwarding" || selectedForwardingMode === "useDefaultModeOnly") {
        rightHandSide += `: ${selectedForwardingUrl}`;
      }

      return `${leftHandSide} → ${rightHandSide}`;
    },
    [checkedDevices, facilities, watchAllFields]
  );

  const getReassignedFacilityInfo = React.useCallback(
    (action) => {
      const oldFacility = facilities.find((aFacility) => aFacility.id === action.device.facilityId);
      const newFacility = action.facility;

      let oldDeviceForwarding;
      // If this device has its own forwarding, display that
      if (action.device.forwardingMode.endsWith("Forwarding")) {
        oldDeviceForwarding = `${deviceForwardingModes[action.device.forwardingMode].name}`;

        // Add the forwarding URL (if not forwarding to Inbox)
        if (action.device.forwardingMode !== "inboxForwarding") {
          oldDeviceForwarding += `: ${action.device.forwardingUrl}`;
        }
      } else {
        oldDeviceForwarding = `${deviceForwardingModes[oldFacility.forwardingMode].name}`;

        // Add either the device or the facility forwarding URL (if not forwarding to Inbox)
        if (action.device.forwardingMode === "useDefaultModeOnly") {
          oldDeviceForwarding += `: ${action.device.forwardingUrl}`;
        } else if (oldFacility.forwardingMode !== "inboxForwarding") {
          oldDeviceForwarding += `: ${oldFacility.deviceForwardingUrl}`;
        }
      }

      // Reassigning the device will give it the new facility's default forwarding mode.
      // Display the facility's default forwarding mode and the forwarding URL (if not forwarding to Inbox)
      let newFacilityForwarding = deviceForwardingModes[newFacility.forwardingMode].name;
      if (newFacility.forwardingMode !== "inboxForwarding") {
        newFacilityForwarding += `: ${newFacility.deviceForwardingUrl}`;
      }

      return `${oldFacility.name} (${oldDeviceForwarding}) → ${newFacility.name} (${newFacilityForwarding})`;
    },
    [facilities]
  );

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <>
      {watchAllFields.actionTypeSelect === "updateForwarding" && (
        <>
          <Grid container>
            <Grid size={12}>
              <Typography component="div">
                Action:&nbsp;&nbsp;
                <Typography
                  color="secondary"
                  sx={{fontStyle: "italic", fontWeight: "regular", display: "inline"}}
                >
                  {userFriendlyTypeNames[watchAllFields.actionTypeSelect]}
                </Typography>
                {multipleDevices && (
                  <Typography sx={{fontWeight: "regular", display: "inline"}}>
                    {` (${checkedDevices.length} selected of ${totalDevices})`}
                  </Typography>
                )}
              </Typography>
            </Grid>
            <Grid size={12} sx={{mt: 2}}>
              <Alert
                message="Updating a device while it is in use could lead to missed device transmissions."
                level="warning"
              />
            </Grid>
          </Grid>
          <Grid container sx={{mt: 2}}>
            {checkedDevices.length === 1 && (
              <Grid
                size={12}
                key={checkedDevices[0].tzSerial}
                data-cy={`${checkedDevices[0].tzSerial}-confirmation-serials`}
              >
                <div>
                  {checkedDevices[0].tzSerial}&nbsp;-&nbsp;
                  <Typography
                    color="secondary"
                    sx={{fontStyle: "italic", fontWeight: "regular", display: "inline"}}
                  >
                    {getUpdatedForwardingMode(checkedDevices[0])}
                  </Typography>
                </div>
              </Grid>
            )}
            {checkedDevices.length > 1 &&
              checkedDevices.map((checkedDevice) => (
                <Grid
                  size={12}
                  key={checkedDevice.tzSerial}
                  data-cy={`${checkedDevice.tzSerial}-confirmation-serials`}
                >
                  <li>
                    {checkedDevice.tzSerial}&nbsp;-&nbsp;
                    <Typography
                      color="secondary"
                      sx={{fontStyle: "italic", fontWeight: "regular", display: "inline"}}
                    >
                      {getUpdatedForwardingMode(checkedDevice)}
                    </Typography>
                  </li>
                </Grid>
              ))}
          </Grid>
        </>
      )}
      {watchAllFields.actionTypeSelect === "updateFirmware" && (
        <>
          <Grid container>
            <Grid size={12}>
              <Typography component="div">
                Action:&nbsp;&nbsp;
                <Typography
                  color="secondary"
                  sx={{fontStyle: "italic", fontWeight: "regular", display: "inline"}}
                >
                  {userFriendlyTypeNames[watchAllFields.actionTypeSelect]}
                </Typography>
                {multipleDevices && (
                  <Typography sx={{fontWeight: "regular", display: "inline"}}>
                    {` (${checkedDevices.length} selected of ${totalDevices})`}
                  </Typography>
                )}
              </Typography>
            </Grid>
          </Grid>
          {devicesToReceiveAction.length > 0 && (
            <Grid container sx={{mt: 2}}>
              {devicesToReceiveAction.length === 1 && (
                <Grid size={12} key={devicesToReceiveAction[0].device.tzSerial}>
                  <div>
                    {devicesToReceiveAction[0].device.tzSerial}&nbsp;-&nbsp;
                    <Typography
                      color="secondary"
                      sx={{fontStyle: "italic", fontWeight: "regular", display: "inline"}}
                    >
                      {`${devicesToReceiveAction[0].device.firmwareVersion} → ${parseVersionFromFileName(
                        devicesToReceiveAction[0].selectedFirmwareVersion
                      )}`}
                    </Typography>
                  </div>
                </Grid>
              )}
              {devicesToReceiveAction.length > 1 &&
                devicesToReceiveAction.map((action) => (
                  <Grid size={12} key={action.device.tzSerial}>
                    <li>
                      {action.device.tzSerial}&nbsp;-&nbsp;
                      <Typography
                        color="secondary"
                        sx={{fontStyle: "italic", fontWeight: "regular", display: "inline"}}
                      >
                        {`${action.device.firmwareVersion} → ${parseVersionFromFileName(
                          action.selectedFirmwareVersion
                        )}`}
                      </Typography>
                    </li>
                  </Grid>
                ))}
            </Grid>
          )}
        </>
      )}
      {watchAllFields.actionTypeSelect.startsWith("reassign") && (
        <>
          <Grid container>
            <Grid size={12}>
              <Typography component="div">
                Action:&nbsp;&nbsp;
                <Typography
                  color="secondary"
                  sx={{fontStyle: "italic", fontWeight: "regular", display: "inline"}}
                >
                  {`${userFriendlyTypeNames.reassign}${devicesToReceiveAction.length === 1 ? "" : "s"}`}
                </Typography>
                {multipleDevices && (
                  <Typography sx={{fontWeight: "regular", display: "inline"}}>
                    {` (${checkedDevices.length} selected of ${totalDevices})`}
                  </Typography>
                )}
              </Typography>
            </Grid>
            <Grid size={12} sx={{mt: 2}}>
              <Alert
                message="Updating a device while it is in use could lead to missed device transmissions."
                level="warning"
              />
            </Grid>
          </Grid>
          <Grid container sx={{mt: 2}}>
            {devicesToReceiveAction.length === 1 && (
              <Grid size={12} key={devicesToReceiveAction[0].device.tzSerial}>
                <div>
                  {devicesToReceiveAction[0].device.tzSerial}&nbsp;-&nbsp;
                  <Typography
                    color="secondary"
                    sx={{fontStyle: "italic", fontWeight: "regular", display: "inline"}}
                  >
                    {getReassignedFacilityInfo(devicesToReceiveAction[0])}
                  </Typography>
                </div>
              </Grid>
            )}
            {devicesToReceiveAction.length > 1 &&
              devicesToReceiveAction.map((action) => (
                <Grid size={12} key={action.device.tzSerial}>
                  <li>
                    {action.device.tzSerial}&nbsp;-&nbsp;
                    <Typography
                      color="secondary"
                      sx={{fontStyle: "italic", fontWeight: "regular", display: "inline"}}
                    >
                      {getReassignedFacilityInfo(action)}
                    </Typography>
                  </li>
                </Grid>
              ))}
          </Grid>
        </>
      )}
    </>
  );
}

DeviceActionsConfirmationMessages.propTypes = {
  checkedDevices: PropTypes.array.isRequired,
  totalDevices: PropTypes.number.isRequired,
  multipleDevices: PropTypes.bool.isRequired,
  watchAllFields: PropTypes.object.isRequired,
  userFriendlyTypeNames: PropTypes.object.isRequired,

  /**
   * Not Required Fields
   */
  devicesToReceiveAction: PropTypes.array,
  facilities: PropTypes.array,
};

export default DeviceActionsConfirmationMessages;
