/* eslint-env browser */
/* eslint-disable complexity */
import React from "react";
import {DateTime} from "luxon";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import DeleteForever from "@mui/icons-material/DeleteForever";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";
import {parseVersionFromFileName} from "../../components/common/FirmwareParsing.js";
import useJwt from "../../components/contexts/useJwt.jsx";
import IconButtonWithTooltip from "../../components/primitives/IconButtonWithTooltip.jsx";
import TableLoading from "../../components/primitives/TableLoading.jsx";

function DevicePendingActionsTable({
  // Props
  checkedDevices,
  multipleDevices,
  userFriendlyTypeNames,
  setError,
}) {
  const {isInAnyRole} = useJwt();

  //---------------------------------------------------------------------------
  // State Management
  //---------------------------------------------------------------------------
  const [pendingActions, setPendingActions] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  //---------------------------------------------------------------------------
  // Helper Functions
  //---------------------------------------------------------------------------
  const getDisplayedActionName = React.useCallback(
    (action) => {
      const actionType = action.subType || action.name;
      let display = actionType;
      if (actionType === "updateFirmware" && action.pendingFirmwareVersion) {
        display = `Update Firmware to ${action.pendingFirmwareVersion}`;
      } else if (userFriendlyTypeNames[actionType]) {
        display = userFriendlyTypeNames[actionType];
      }
      return display;
    },
    [userFriendlyTypeNames]
  );

  const checkIfLaterPendingAction = React.useCallback(
    (actionToCheck) => {
      const actionTypeToCheck = actionToCheck.subType || actionToCheck.name;
      const actionTimeToCheck = DateTime.fromISO(actionToCheck.createdAt);

      return pendingActions.some(({name, subType, createdAt}) => {
        const actionType = subType || name;
        const createdAtDatetime = DateTime.fromISO(createdAt);

        if (actionTypeToCheck === "updateSettings" || actionTypeToCheck === "convertMctToCem") {
          return (
            (actionType === "updateSettings" ||
              actionType === "convertMctToCem" ||
              actionType === "replaceDevice") &&
            createdAtDatetime > actionTimeToCheck
          );
        }
        return actionType === actionTypeToCheck && createdAtDatetime > actionTimeToCheck;
      });
    },
    [pendingActions]
  );

  const displayDeleteButtonForAction = React.useCallback(
    (action) => {
      const actionType = action.subType || action.name;

      let displayButton = true;
      if (
        actionType === "formatDevice" ||
        (actionType === "updateSettings" && checkIfLaterPendingAction(action)) ||
        (actionType === "convertMctToCem" && checkIfLaterPendingAction(action)) ||
        (actionType === "forceActionsFailure" && !isInAnyRole(["tzAdmin", "warehouse"])) ||
        (actionType === "replaceDevice" && !action.canDelete)
      ) {
        displayButton = false;
      }
      return displayButton;
    },
    [checkIfLaterPendingAction, isInAnyRole]
  );

  //---------------------------------------------------------------------------
  // Load data for API
  //---------------------------------------------------------------------------
  const getAndSetActions = React.useCallback(async () => {
    if (multipleDevices || checkedDevices.length !== 1) {
      setPendingActions([]);
      return;
    }

    const {data: actions} = await axios({
      method: "get",
      url: "/actions",
      params: {
        deviceId: checkedDevices[0].id,
        status: "pending",
      },
    });

    // For each replace device action, get the action created for the new device
    const results = await Promise.all(
      actions.map((action) => {
        const actionType = action.subType || action.name;
        if (actionType === "replaceDevice") {
          try {
            const data = JSON.parse(action.data);
            if (data.newEnrollment?.deviceId) {
              return axios({
                method: "get",
                url: "/actions",
                params: {
                  deviceId: data.newEnrollment.deviceId,
                  name: "replaceDevice",
                },
              });
            }
          } catch (e) {
            /* Do Nothing */
          }
        }
        return Promise.resolve([]);
      })
    );

    const sortedActions = actions.sort((a, b) =>
      a.createdAt > b.createdAt ? -1 : 1 || b.actionCount - a.actionCount
    );
    const formattedActions = sortedActions.map((action, index) => {
      const actionType = action.subType || action.name;

      return {
        ...action,

        // Parse the pending version of Update Firmware actions and put it onto those actions
        ...(actionType === "updateFirmware" &&
          action.data && {pendingFirmwareVersion: parseVersionFromFileName(action.data)}),

        // Replace device actions that have not yet been sent to the new device can be deleted
        canDelete: results[index].length === 1 && results[index][0].status === "pending",
      };
    });
    setPendingActions(formattedActions);
  }, [checkedDevices, multipleDevices]);

  React.useEffect(() => {
    if (loading) {
      getAndSetActions().then(() => setLoading(false));
    }
  }, [loading, getAndSetActions]);

  //---------------------------------------------------------------------------
  // Form Functions
  //---------------------------------------------------------------------------
  const removeAction = React.useCallback(
    async (action) => {
      const actionToRemoveType = action.subType || action.name;

      let url = `/actions/${action.id}`;
      if (actionToRemoveType === "replaceDevice") {
        url = `/studies/replaceDevice/${action.studyId}`;
      }

      try {
        await axios({
          method: "delete",
          url,
        });

        setLoading(true);
      } catch (err) {
        setError(`Unable to delete action. Status Code: ${err.status}`);
      }
    },
    [setLoading, setError]
  );

  if (loading) {
    return (
      <DialogContent>
        <Divider variant="middle" />
        <TableLoading marginTop={2} size={50} />
      </DialogContent>
    );
  }

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <DialogContent>
      <Divider variant="middle" sx={{mx: 0}} />

      {pendingActions.length <= 0 && (
        <Typography align="center" variant="body2" data-cy="no-pending-actions-table" sx={{mt: 2}}>
          There are no pending actions for this device
        </Typography>
      )}
      {pendingActions.length > 0 && (
        <>
          <Typography variant="h6" sx={{m: 2}}>
            Pending Actions
          </Typography>

          <Table size="small" data-cy="pending-actions-table">
            <TableHead>
              <TableRow>
                <TableCell>Action Name</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell width="10%" />
              </TableRow>
            </TableHead>

            <TableBody>
              {pendingActions.map((action, index) => (
                <TableRow key={action.id} data-cy={`action-${index}`}>
                  <TableCell data-cy={`action-name-${index}`}>{getDisplayedActionName(action)}</TableCell>

                  <TableCell data-cy={`action-created-at-${index}`}>
                    {DateTime.fromISO(action.createdAt).toFormat("yyyy-LL-dd hh:mm:ss (ZZZZ)")}
                  </TableCell>

                  <TableCell data-cy={`action-created-by-${index}`}>{action.createdBy}</TableCell>

                  <TableCell width="10%">
                    {displayDeleteButtonForAction(action) && (
                      <IconButtonWithTooltip
                        title="Delete"
                        color="secondary"
                        data-cy={`delete-pending-action-button-${action.id}`}
                        onClick={() => removeAction(action)}
                        otherProps={{size: "small", sx: {padding: 0}}}
                      >
                        <DeleteForever fontSize="small" />
                      </IconButtonWithTooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}
    </DialogContent>
  );
}

DevicePendingActionsTable.propTypes = {
  checkedDevices: PropTypes.array.isRequired,
  multipleDevices: PropTypes.bool.isRequired,
  userFriendlyTypeNames: PropTypes.object.isRequired,
  setError: PropTypes.func.isRequired,
};

export default DevicePendingActionsTable;
