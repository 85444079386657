/* eslint-env browser */
/* eslint-disable complexity */
import React from "react";
import {useForm, useFormState} from "react-hook-form";
import keyBy from "lodash/keyBy";
import {useConfirm} from "material-ui-confirm";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Close from "@mui/icons-material/Close";
import MoreVert from "@mui/icons-material/MoreVert";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";
import {
  getFirmwareReleasesByDeviceType,
  parseVersionFromFileName,
} from "../../components/common/FirmwareParsing.js";
import useJwt from "../../components/contexts/useJwt.jsx";
import AutocompleteInput from "../../components/form-inputs/AutocompleteInput.jsx";
import FormStringInput from "../../components/form-inputs/FormStringInput.jsx";
import TridentDeviceIcon from "../../components/icons/TridentDeviceIcon.jsx";
import Alert from "../../components/primitives/Alert.jsx";
import CancelButton from "../../components/primitives/CancelButton.jsx";
import DialogTitleBar from "../../components/primitives/DialogTitleBar.jsx";
import {getDeviceForwardingDescription} from "../../components/primitives/ForwardingModes.jsx";
import IconButtonWithTooltip from "../../components/primitives/IconButtonWithTooltip.jsx";
import TableLoading from "../../components/primitives/TableLoading.jsx";
import DeviceActionsConfirmationMessages from "./DeviceActionsConfirmationMessages.jsx";
import DeviceActionsWarningMessages from "./DeviceActionsWarningMessages.jsx";
import DevicePendingActionsTable from "./DevicePendingActionsTable.jsx";

function DeviceActionsForm({
  // Props
  devices,
  checkedRows,
  multipleDevices,
  setCheckedRows,
  setTableReload,
}) {
  const {isInAnyRole, userFacilityId, fullName, facilityDisabled} = useJwt();

  const userFriendlyTypeNames = React.useMemo(() => {
    return {
      updateFirmware: "Update Firmware",
      updateForwarding: "Update Forwarding", // Not a regular action
      requestErrorLogs: "Request Error Logs",
      requestEcgData: "Request ECG Data",
      sendMessage: "Send Message",
      endStudy: "End Study",
      updateSettings: "Update Settings",
      formatDevice: "Check In Device",
      forceCheckIn: "Force Check In Device",
      forceActionsFailure: "Force Actions Failure",
      replaceDevice: "Replace Device on Patient",
      convertMctToCem: "Downgrade to CEM Study",
      convertMctWithFullDisclosureToCem: "Downgrade to CEM Study",
      resumeStudy: "Resume Study",
      reassign: "Reassign Device",
      reassignAndSuspend: "Reassign Device",
    };
  }, []);

  //---------------------------------------------------------------------------
  // Modal state management
  //---------------------------------------------------------------------------
  const [checkedDevices, setCheckedDevices] = React.useState([]);
  const [deviceTypes, setDeviceTypes] = React.useState([]);
  const [devicesByDeviceType, setDevicesByDeviceType] = React.useState([]);
  const [facilities, setFacilities] = React.useState([]);
  const [facilitiesForReassignment, setFacilitiesForReassignment] = React.useState([]);
  const [actionOptions, setActionOptions] = React.useState([]);
  const [firmwareOptions, setFirmwareOptions] = React.useState({});
  const [forwardingModeOptions, setForwardingModeOptions] = React.useState([]);
  const [devicesInActiveStudy, setDevicesInActiveStudy] = React.useState([]);

  //---------------------------------------------------------------------------
  // Form Management
  //---------------------------------------------------------------------------
  const {handleSubmit, control, watch} = useForm();
  const {isDirty, isValid} = useFormState({control});
  const watchActionTypeSelect = watch("actionTypeSelect") || "";
  const watchAllFields = watch();

  //---------------------------------------------------------------------------
  // Update button state management
  //---------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(true);

  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  //---------------------------------------------------------------------------
  // Set up hook for confirmation dialogs
  //---------------------------------------------------------------------------
  const confirm = useConfirm();

  //---------------------------------------------------------------------------
  // Helper Functions
  //---------------------------------------------------------------------------
  const isOnlyOneFacilityChecked = React.useCallback(() => {
    const checkedFacility = checkedRows[0].facilityId;
    const foundDifferentFacility = checkedRows.find(
      (checkedDevice) => checkedDevice.facilityId !== checkedFacility
    );
    return foundDifferentFacility === undefined;
  }, [checkedRows]);

  const isCheckedBoxEnabled = React.useCallback(() => {
    const deviceStatusTypes = [
      "No SIM Card",
      "Active - On Patient",
      "Active - Idle",
      "Active - Offline",
      "Suspended",
    ];

    return deviceStatusTypes.includes(checkedRows[0]?.status);
  }, [checkedRows]);

  const getActionTypes = React.useCallback(() => {
    // Getting action options for the actions dropdown
    const actionTypes = [
      {id: "updateFirmware", name: "Update Firmware"},
      {id: "updateForwarding", name: "Update Forwarding"},
      {id: "reassign", name: "Reassign and Activate Device"},
      {id: "reassignAndSuspend", name: "Reassign and Suspend Device"},
    ];

    if (
      isInAnyRole(["tzAdmin"]) &&
      checkedRows.length === 1 &&
      checkedRows[0].forwardingDestination === "BitRhythm Inbox" &&
      checkedRows[0].firmwareVersion.replace("v", "") >= "1.8" &&
      checkedRows[0].iccid !== ""
    ) {
      actionTypes.push({id: "forceCheckIn", name: "Force Check In"});
    }

    // If any device has pending state changes or is a Holter in this list, check which actions are allowed.
    if (
      checkedRows.some((checkedDevice) => checkedDevice.iccid === "" || checkedDevice.pendingState !== null)
    ) {
      // If every device is a Holter with no pending state changes, replace the cellular
      // reassign actions with one normal reassign action
      if (
        checkedRows.every(
          (checkedDevice) => checkedDevice.iccid === "" && checkedDevice.pendingState === null
        )
      ) {
        actionTypes.splice(
          actionTypes.findIndex(({id}) => id === "reassign"),
          1,
          {name: "Reassign Device", id: "reassign"}
        );
        actionTypes.splice(
          actionTypes.findIndex(({id}) => id === "reassignAndSuspend"),
          1
        );
      }

      // If any device has pending state changes, remove the reassign actions.
      else if (checkedRows.some((checkedDevice) => checkedDevice.pendingState !== null)) {
        actionTypes.splice(
          actionTypes.findIndex(({id}) => id === "reassign"),
          1
        );
        actionTypes.splice(
          actionTypes.findIndex(({id}) => id === "reassignAndSuspend"),
          1
        );
      }
    }

    return actionTypes;
  }, [checkedRows, isInAnyRole]);

  const getDeviceTypeName = React.useCallback(
    (deviceTypeId) => {
      return deviceTypes.find((deviceType) => deviceType.id === deviceTypeId)?.name;
    },
    [deviceTypes]
  );

  const updateForwardingTooltip = React.useCallback(
    (deviceTypeId) => {
      const selectedForwardingMode = watchAllFields[`forwardingMode-${deviceTypeId}`];
      if (!selectedForwardingMode) {
        return "";
      }
      const foundFacility = facilities.find((aFacility) => aFacility.id === checkedDevices[0].facilityId);
      return getDeviceForwardingDescription(
        selectedForwardingMode,
        foundFacility.forwardingMode,
        foundFacility.deviceForwardingUrl
      );
    },
    [watchAllFields, checkedDevices, facilities]
  );

  const getTzSerialFromCreatedAction = React.useCallback(
    (action) => {
      let tzSerial = "";
      if (action.tzSerial) {
        tzSerial = action.tzSerial;
      } else if (action.deviceId) {
        const deviceForAction = devices.find((aDevice) => aDevice.id === action.deviceId);
        if (deviceForAction?.tzSerial) {
          tzSerial = deviceForAction.tzSerial;
        }
      } else if (action.config?.url) {
        const deviceForAction = devices.find((aDevice) => {
          const urlParts = action.config.url.split("/");
          return aDevice.id === urlParts[urlParts.length - 1];
        });
        if (deviceForAction?.tzSerial) {
          tzSerial = deviceForAction.tzSerial;
        }
      }
      return tzSerial;
    },
    [devices]
  );

  //---------------------------------------------------------------------------
  // Handle dialog management
  //---------------------------------------------------------------------------
  const [open, setOpen] = React.useState(false);
  const handleOpen = React.useCallback(async () => {
    if (isOnlyOneFacilityChecked() || isInAnyRole(["facilityAdmin"])) {
      setOpen(true);
    } else {
      try {
        await confirm({
          title: "Error processing request",
          content: (
            <Alert
              message="Cannot create actions for devices at multiple facilities at once. Please select only one facility's devices at a time."
              level="error"
            />
          ),
          confirmationText: "Ok",
          hideCancelButton: true,
          dialogProps: {maxWidth: "sm"},
        });
      } catch (err) {
        /* do nothing */
      }
      setCheckedRows([]);
    }
  }, [isOnlyOneFacilityChecked, isInAnyRole, setCheckedRows, confirm]);

  const handleClose = React.useCallback(
    (event, reason) => {
      if (reason === "backdropClick") {
        return;
      }
      setError(null);
      setOpen(false);
    },
    [setError, setOpen]
  );

  //---------------------------------------------------------------------------
  // Load data from the API
  //---------------------------------------------------------------------------
  const getActionsData = React.useCallback(async () => {
    const activeStudyDevices = checkedRows.filter((checkedDevice) => !checkedDevice.availableForStudy);
    setDevicesInActiveStudy(activeStudyDevices);

    const actionTypes = getActionTypes();

    // Fetch API data
    const facilityParams = {};
    if (!isInAnyRole(["tzAdmin", "warehouse"])) {
      facilityParams.id = {$or: [{$like: `${userFacilityId}_%`}, {$eq: userFacilityId}]};
    }

    try {
      const promiseArray = [
        axios({
          method: "get",
          url: "/deviceTypes",
        }),
        axios({
          method: "get",
          url: "/firmware/storage/filePaths",
        }),
        axios({
          method: "get",
          url: "/facilities",
          params: facilityParams,
        }),
        axios({
          method: "get",
          url: "/deviceVariants",
        }),
      ];

      const [
        {data: deviceTypesResponse},
        {data: filePathsResponse},
        {data: facilitiesResponse},
        {data: variantsResponse},
      ] = await Promise.all(promiseArray);

      deviceTypesResponse.map((deviceType) => {
        if (deviceType.name) {
          return Object.assign(deviceType, {
            type: deviceType.name.replace(/\s/g, "-").toLowerCase(),
          });
        }
        return deviceType;
      });

      // Format firmware versions by device types
      const filePaths = filePathsResponse.map((firmwarePath) => ({
        id: firmwarePath,
        version: parseVersionFromFileName(firmwarePath),
      }));

      setDeviceTypes(deviceTypesResponse);
      setFacilities(facilitiesResponse);

      const reassignmentFacilities = facilitiesResponse.filter(
        (aFacility) => aFacility.id !== checkedRows[0].facilityId
      );

      reassignmentFacilities.sort((a, b) => (a.name > b.name ? 1 : -1));
      setFacilitiesForReassignment(reassignmentFacilities);

      const firmwareReleases = getFirmwareReleasesByDeviceType(
        filePaths,
        deviceTypesResponse,
        isInAnyRole(["tzAdmin", "warehouse"])
      );

      // If there are no facilities, don't allow reassignments
      while (
        reassignmentFacilities.length === 0 &&
        actionTypes.some((action) => action.id.startsWith("reassign"))
      ) {
        actionTypes.splice(
          actionTypes.findIndex((action) => action.id.startsWith("reassign")),
          1
        );
      }

      // Attach deviceTypeId to each device
      const deviceVariantsByGtin = keyBy(variantsResponse, "gtin");
      const updatedCheckedDevices = [...checkedRows];
      updatedCheckedDevices.map((checkedDevice) =>
        Object.assign(checkedDevice, {
          deviceTypeId: deviceVariantsByGtin[checkedDevice.gtin]?.deviceType?.id || null,
        })
      );
      setCheckedDevices(updatedCheckedDevices);

      //---------------------------------------------------------------------------
      // Group devices together by device type in this format:
      //
      // devicesByDeviceType = [
      //   {
      //     deviceTypeId: 1,
      //     devices: [{device1}, {device2}],
      //     availableFirmwareVersions: [{firmware1}, {firmware2}],
      //   },
      //   {
      //     deviceTypeId: 3,
      //     devices: [{device3}, {device4}],
      //     availableFirmwareVersions: [{firmware1}, {firmware2}],
      //   }
      // ]
      //---------------------------------------------------------------------------
      const devicesByType = [];
      deviceTypesResponse.forEach((deviceType) => {
        const devicesOfThisDeviceType = updatedCheckedDevices.filter(
          (checkedDevice) => checkedDevice.deviceTypeId === deviceType.id
        );

        // Only get firmware releases and attach to devicesByDeviceType if any devices of this type exist
        if (devicesOfThisDeviceType.length > 0) {
          // Get an array of all firmware releases for this device type
          devicesByType.push({
            deviceTypeId: deviceType.id,
            devices: devicesOfThisDeviceType,
          });
        }
      });
      setDevicesByDeviceType(devicesByType);

      // Assuming all devices are assigned to the same facility, get the
      // forwarding mode options
      const foundFacility = facilitiesResponse.find(
        (aFacility) => aFacility.id === updatedCheckedDevices[0].facilityId
      );

      const forwardingModes = [
        {id: "nativeForwarding", name: "Native Forwarding"},
        {
          id: "useDefaultModeAndAddress",
          name: foundFacility.forwardingMode
            ? `Facility Default (${updatedCheckedDevices[0].facility.readableForwardingMode})`
            : "Facility Default",
        },
      ];
      // inboxForwarding is only allowed if the Facility has Inbox access or user is tzAdmin or warehouse
      if (foundFacility.inboxAccess || isInAnyRole(["tzAdmin", "warehouse"])) {
        forwardingModes.unshift({name: "BitRhythm Inbox", id: "inboxForwarding"});
      }

      if (foundFacility.forwardingMode !== "inboxForwarding") {
        forwardingModes.push({
          name: foundFacility.forwardingMode
            ? `Facility Default (${updatedCheckedDevices[0].readableForwardingMode}) with Device URL`
            : "Facility Default with Device URL",
          id: "useDefaultModeOnly",
        });
      }

      setForwardingModeOptions(forwardingModes);
      setFirmwareOptions(firmwareReleases);
      setActionOptions(actionTypes);
    } catch (err) {
      setError(err.message);
    }
  }, [checkedRows, getActionTypes, isInAnyRole, userFacilityId]);

  React.useEffect(() => {
    if (loading || checkedDevices.length !== checkedRows.length) {
      getActionsData().then(() => setLoading(false));
    }
  }, [loading, checkedDevices, checkedRows, getActionsData]);

  //---------------------------------------------------------------------------
  // Form Submission
  //---------------------------------------------------------------------------
  const addEditDeviceUrlAction = React.useCallback(
    async (submitData) => {
      const data = [];
      // For each device, gather the deviceId and the forwarding information according to its device type
      devicesByDeviceType.forEach((deviceTypeGroup) => {
        const url = ["nativeForwarding", "useDefaultModeOnly"].includes(
          submitData[`forwardingMode-${deviceTypeGroup.deviceTypeId}`]
        )
          ? submitData[`forwardingUrl-${deviceTypeGroup.deviceTypeId}`]
          : "";

        data.push(
          ...deviceTypeGroup.devices.map(({tzSerial}) => {
            return {
              tzSerial,
              properties: {
                forwardingUrl: url,
                forwardingMode: submitData[`forwardingMode-${deviceTypeGroup.deviceTypeId}`],
              },
            };
          })
        );
      });

      const confirmationMessage = (
        <DeviceActionsConfirmationMessages
          watchAllFields={watchAllFields}
          checkedDevices={checkedDevices}
          totalDevices={devices.length}
          facilities={facilities}
          multipleDevices={multipleDevices}
          userFriendlyTypeNames={userFriendlyTypeNames}
        />
      );

      try {
        await confirm({
          title: "Are you sure you want to make the following updates?",
          content: confirmationMessage,
          confirmationText: userFriendlyTypeNames[watchActionTypeSelect],
          dialogProps: {maxWidth: "md"},
        });
      } catch (err) {
        return {success: [], failure: []};
      }

      setLoading(true);
      const {data: patchResponse} = await axios({
        method: "patch",
        url: "/devices",
        data,
      });

      return patchResponse;
    },
    [
      confirm,
      devices,
      multipleDevices,
      checkedDevices,
      facilities,
      devicesByDeviceType,
      userFriendlyTypeNames,
      watchAllFields,
      watchActionTypeSelect,
    ]
  );

  const addUpdateFirmwareAction = React.useCallback(
    async (submitData) => {
      const devicesToReceiveAction = [];

      Object.values(devicesByDeviceType).forEach((deviceTypeGroup) => {
        // Group each device with its selected firmware version
        devicesToReceiveAction.push(
          ...deviceTypeGroup.devices.map((aDevice) => {
            return {
              device: aDevice,
              selectedFirmwareVersion: submitData[`firmwareVersion-${deviceTypeGroup.deviceTypeId}`],
              actionComment: submitData[`comment-${deviceTypeGroup.deviceTypeId}`],
            };
          })
        );
      });

      try {
        const confirmationMessage = (
          <DeviceActionsConfirmationMessages
            watchAllFields={watchAllFields}
            checkedDevices={checkedDevices}
            devicesToReceiveAction={devicesToReceiveAction}
            totalDevices={devices.length}
            multipleDevices={multipleDevices}
            userFriendlyTypeNames={userFriendlyTypeNames}
          />
        );

        await confirm({
          title: "Are you sure you want to make the following updates?",
          content: confirmationMessage,
          confirmationText: userFriendlyTypeNames[watchActionTypeSelect],
          dialogProps: {maxWidth: "sm"},
        });
      } catch (err) {
        return {success: [], failure: []};
      }

      setLoading(true);
      const actions = devicesToReceiveAction.map((action) => ({
        deviceId: action.device.id,
        facilityId: userFacilityId,
        createdBy: fullName,
        comment: action.actionComment,
        data: action.selectedFirmwareVersion,
      }));

      const {data: postResponse} = await axios({
        method: "post",
        url: `actions/${watchActionTypeSelect}/bulk`,
        data: actions,
      });

      return {success: postResponse, failure: []};
    },
    [
      devicesByDeviceType,
      watchActionTypeSelect,
      watchAllFields,
      checkedDevices,
      devices.length,
      multipleDevices,
      userFriendlyTypeNames,
      confirm,
      userFacilityId,
      fullName,
    ]
  );

  const addForceCheckInAction = React.useCallback(async () => {
    const selectedDevice = devicesByDeviceType[0].devices[0];

    setLoading(true);
    await axios({
      method: "post",
      url: `/devices/${selectedDevice.tzSerial}/check-in`,
      params: {remote: true, force: true},
      responseType: "arraybuffer",
    });

    return {success: [{tzSerial: selectedDevice.tzSerial}], failure: []};
  }, [devicesByDeviceType]);

  const reassignDevices = React.useCallback(
    async (submitData, operationalState) => {
      const devicesToAssign = [];
      const deviceInfoForConfirmation = [];

      // For each device type, gather the devices and the selected facility ID
      devicesByDeviceType.forEach((deviceTypeGroup) => {
        deviceTypeGroup.devices.forEach((aDevice) => {
          devicesToAssign.push({
            tzSerial: aDevice.tzSerial,
            properties: {
              facilityId: submitData[`facilityForReassignment-${deviceTypeGroup.deviceTypeId}`].id,
              operationalState: aDevice.iccid ? operationalState : "active",
            },
          });
          deviceInfoForConfirmation.push({
            device: aDevice,
            facility: submitData[`facilityForReassignment-${deviceTypeGroup.deviceTypeId}`],
          });
        });
      });

      const buttonMessage = `${userFriendlyTypeNames.reassign}${
        deviceInfoForConfirmation.length === 1 ? "" : "s"
      }`;

      const confirmationMessage = (
        <DeviceActionsConfirmationMessages
          watchAllFields={watchAllFields}
          checkedDevices={checkedDevices}
          devicesToReceiveAction={deviceInfoForConfirmation}
          totalDevices={devices.length}
          multipleDevices={multipleDevices}
          facilities={facilities}
          userFriendlyTypeNames={userFriendlyTypeNames}
        />
      );

      // Facility Name (Device Mode: URL) --> Facility Name (Facility Mode: URL)
      // If there are no devices that can receive the action, the confirmation popup will just be a cancel popup
      try {
        await confirm({
          title: "Are you sure you want to make the following updates?",
          content: confirmationMessage,
          confirmationText: buttonMessage,
          dialogProps: {maxWidth: "md"},
        });
      } catch (err) {
        return {success: [], failure: []};
      }

      setLoading(true);
      const {data: patchResponse} = await axios({
        method: "patch",
        url: "/devices",
        data: devicesToAssign,
      });

      return patchResponse;
    },
    [
      confirm,
      devices,
      multipleDevices,
      checkedDevices,
      devicesByDeviceType,
      watchAllFields,
      facilities,
      userFriendlyTypeNames,
    ]
  );

  const onSubmit = async (data) => {
    try {
      let response;
      if (watchActionTypeSelect === "updateForwarding") {
        response = await addEditDeviceUrlAction(data);
      } else if (watchActionTypeSelect === "updateFirmware") {
        response = await addUpdateFirmwareAction(data);
      } else if (watchActionTypeSelect === "forceCheckIn") {
        response = await addForceCheckInAction();
      } else if (watchActionTypeSelect.startsWith("reassign")) {
        const operationalState = watchActionTypeSelect.endsWith("Suspend") ? "inactive" : "active";
        response = await reassignDevices(data, operationalState);
      }

      setLoading(false);
      handleClose();

      const {success, failure} = response;

      if (success.length > 0 || failure.length > 0) {
        let confirmationTitle = `${success.length} action${
          success.length === 1 ? "" : "s"
        } created successfully`;
        if (failure.length > 0) {
          confirmationTitle += ` (${failure.length} failed)`;
        }

        const confirmationMessage = (
          <Grid container spacing={2}>
            <Divider pb={3} />
            {success.length > 0 && (
              <>
                <Grid size={12}>
                  <Typography>
                    <b>Succeeded: ({success.length})</b>
                  </Typography>
                </Grid>
                <Grid size={12}>
                  {success.map((action) => (
                    <li key={action.tzSerial || action.deviceId || action.config?.url}>
                      {getTzSerialFromCreatedAction(action)}
                    </li>
                  ))}
                </Grid>
              </>
            )}
            {failure.length > 0 && (
              <>
                <Divider />
                <Grid size={12}>
                  <Typography>
                    <b>Failed: ({failure.length})</b>
                  </Typography>
                </Grid>
                <Grid size={12}>
                  {failure.map((action) => (
                    <li key={action.tzSerial || action.deviceId || action.config?.url}>
                      {getTzSerialFromCreatedAction(action)}
                    </li>
                  ))}
                </Grid>
              </>
            )}
          </Grid>
        );

        await confirm({
          title: confirmationTitle,
          content: confirmationMessage,
          hideCancelButton: true,
          dialogProps: {maxWidth: "xs"},
        });

        setCheckedRows([]);
        setTableReload(true);
      }
    } catch (err) {
      setError(`Unable to create action. Message: ${err.message}`);
      setLoading(false);
    }
  };

  if (facilityDisabled) {
    return null;
  }

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <>
      {!multipleDevices &&
        isInAnyRole(["tzAdmin", "facilityAdmin", "warehouse"]) &&
        !facilityDisabled &&
        isCheckedBoxEnabled() && (
          <IconButtonWithTooltip
            title="Device Actions"
            color="secondary"
            data-cy={`${checkedDevices[0]?.tzSerial}-device-actions`}
            onClick={handleOpen}
            otherProps={{disabled: loading, sx: {ml: 1}}}
          >
            <MoreVert />
          </IconButtonWithTooltip>
        )}

      {multipleDevices && (
        <IconButtonWithTooltip
          title="Device Actions"
          data-cy="devices-device-actions"
          onClick={handleOpen}
          otherProps={{sx: {color: "white", mr: 1}}}
        >
          <MoreVert />
        </IconButtonWithTooltip>
      )}

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth data-cy="device-actions-form">
        <Alert message={error} setMessage={setError} level="error" />

        <DialogTitleBar title="Device Actions" icon={<TridentDeviceIcon color="secondary" />}>
          <CancelButton isDirty={isDirty} color="tertiary" onClick={handleClose}>
            <Close />
          </CancelButton>
        </DialogTitleBar>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <DialogContent>
            <>
              {loading && <TableLoading />}

              {!loading && actionOptions.length <= 0 && (
                <Typography variant="h6" sx={{m: 2}}>
                  No actions are available
                </Typography>
              )}

              {!loading && actionOptions.length > 0 && (
                <>
                  <Grid container spacing={4} sx={{alignItems: "center"}}>
                    <Grid size={6}>
                      <FormStringInput
                        control={control}
                        label="Action"
                        defaultValue=""
                        name="actionTypeSelect"
                        data-cy="action-type-options"
                        disabled={actionOptions.length === 0 || loading}
                        options={actionOptions}
                      />
                    </Grid>
                    {!multipleDevices && checkedDevices.length === 1 && (
                      <Grid size={6} sx={{display: "flex", alignItems: "center"}}>
                        <TridentDeviceIcon
                          color="tertiary"
                          firmwareId={checkedDevices[0].deviceVariant?.deviceType?.firmwareId}
                        />
                        &nbsp;&nbsp;{checkedDevices[0].tzSerial}
                      </Grid>
                    )}
                    {multipleDevices && (
                      <Grid size={6}>
                        <Typography variant="body1">
                          <b>
                            {checkedDevices.length} selected of {devices.length}
                          </b>
                        </Typography>
                      </Grid>
                    )}
                  </Grid>

                  {/* WARNING MESSAGES */}
                  <DeviceActionsWarningMessages
                    actionType={watchActionTypeSelect}
                    devicesInActiveStudy={devicesInActiveStudy}
                    multipleDevices={multipleDevices}
                  />

                  {watchActionTypeSelect !== "" &&
                    devicesByDeviceType.map((deviceTypeGroup) => (
                      <Grid
                        container
                        spacing={2}
                        key={deviceTypeGroup.deviceTypeId}
                        sx={{alignItems: "flex-start", mt: 1}}
                      >
                        {multipleDevices && checkedDevices.length !== 1 && (
                          <Grid size={12}>
                            <Typography variant="h6">
                              {getDeviceTypeName(deviceTypeGroup.deviceTypeId)}&nbsp;&nbsp;
                              <i>
                                ({deviceTypeGroup.devices.length} of {checkedDevices.length} selected)
                              </i>
                            </Typography>
                          </Grid>
                        )}

                        {/* UPDATE FIRMWARE */}
                        {watchActionTypeSelect === "updateFirmware" && (
                          <>
                            <Grid size={{xs: 12, md: 6}}>
                              <FormStringInput
                                control={control}
                                label="Firmware Version"
                                defaultValue=""
                                name={`firmwareVersion-${deviceTypeGroup.deviceTypeId}`}
                                data-cy={`firmware-version-options-${deviceTypeGroup.deviceTypeId}`}
                                disabled={
                                  firmwareOptions[deviceTypeGroup.deviceTypeId].length === 0 || loading
                                }
                                options={firmwareOptions[deviceTypeGroup.deviceTypeId]}
                                required
                                rules={{
                                  required: "Firmware version is required",
                                }}
                              />
                            </Grid>
                            <Grid size={12}>
                              <FormStringInput
                                control={control}
                                label="Comment"
                                defaultValue=""
                                name={`comment-${deviceTypeGroup.deviceTypeId}`}
                                data-cy={`comment-input-${deviceTypeGroup.deviceTypeId}`}
                                disabled={loading}
                                required
                                size="small"
                                otherProps={{variant: "outlined", multiline: true, rows: 3}}
                                rules={{
                                  required: "Comment is required",
                                  maxLength: {
                                    value: 1000,
                                    message: "Comment exceeds max character limit",
                                  },
                                }}
                              />
                            </Grid>
                          </>
                        )}

                        {/* UPDATE FORWARDING */}
                        {watchActionTypeSelect === "updateForwarding" && (
                          <>
                            <Grid size={{xs: 12, md: 6}}>
                              <FormStringInput
                                control={control}
                                label="Forwarding Mode"
                                defaultValue=""
                                name={`forwardingMode-${deviceTypeGroup.deviceTypeId}`}
                                data-cy={`forwarding-mode-options-${deviceTypeGroup.deviceTypeId}`}
                                disabled={forwardingModeOptions.length === 0 || loading}
                                options={forwardingModeOptions}
                                required
                                rules={{required: "Forwarding mode is required"}}
                              />
                            </Grid>
                            {(watchAllFields[`forwardingMode-${deviceTypeGroup.deviceTypeId}`] ===
                              "nativeForwarding" ||
                              watchAllFields[`forwardingMode-${deviceTypeGroup.deviceTypeId}`] ===
                                "useDefaultModeOnly") && (
                              <Grid size={{xs: 12, md: 6}}>
                                <FormStringInput
                                  control={control}
                                  label="Forwarding URL"
                                  defaultValue=""
                                  name={`forwardingUrl-${deviceTypeGroup.deviceTypeId}`}
                                  data-cy={`forwarding-url-input-${deviceTypeGroup.deviceTypeId}`}
                                  disabled={loading}
                                  required
                                  rules={{required: "Forwarding URL is required"}}
                                />
                              </Grid>
                            )}
                            {watchAllFields[`forwardingMode-${deviceTypeGroup.deviceTypeId}`] !== "" && (
                              <Grid size={12} data-cy="update-forwarding-description">
                                <Alert
                                  message={updateForwardingTooltip(deviceTypeGroup.deviceTypeId)}
                                  level="info"
                                />
                              </Grid>
                            )}
                          </>
                        )}

                        {/* REASSIGN */}
                        {watchActionTypeSelect.startsWith("reassign") && (
                          <>
                            <Grid size={6}>
                              <AutocompleteInput
                                control={control}
                                label="Facility"
                                defaultValue={null}
                                name={`facilityForReassignment-${deviceTypeGroup.deviceTypeId}`}
                                data-cy={`facility-reassignment-options-${deviceTypeGroup.deviceTypeId}`}
                                options={[...facilitiesForReassignment]}
                                variant="single"
                                required
                                rules={{required: "Facility is required"}}
                              />
                            </Grid>
                            <Grid size={6} sx={{display: "inline-flex"}}>
                              <Typography sx={{mt: 2}}>
                                <i>(Currently assigned to {checkedDevices[0].facility.name})</i>
                              </Typography>
                            </Grid>
                          </>
                        )}

                        {/* FORCE CHECK IN */}
                        {watchActionTypeSelect === "forceCheckIn" && (
                          <Grid size={6}>
                            <FormStringInput
                              control={control}
                              label="Device Serial"
                              defaultValue=""
                              name={`deviceSerial-${deviceTypeGroup.deviceTypeId}`}
                              data-cy={`device-serial-input-${deviceTypeGroup.deviceTypeId}`}
                              disabled={loading}
                              required
                              rules={{
                                required: "Device serial is required",
                                validate: {
                                  matchingSerial: (input) => {
                                    if (checkedDevices[0].tzSerial !== input) {
                                      return "The selected device does not match the entered device";
                                    }
                                    return true;
                                  },
                                },
                              }}
                            />
                          </Grid>
                        )}
                      </Grid>
                    ))}
                </>
              )}
            </>
          </DialogContent>
          <DialogActions>
            {watchActionTypeSelect !== "" && (
              <>
                <Box sx={{m: 2}}>
                  <CancelButton color="secondary" isDirty={isDirty} onClick={handleClose}>
                    Cancel
                  </CancelButton>
                </Box>
                <Box sx={{m: 2}}>
                  <LoadingButton
                    data-cy="submit-button"
                    disabled={loading || !isDirty || !isValid}
                    variant="contained"
                    color="secondary"
                    loading={loading}
                    type="submit"
                  >
                    {userFriendlyTypeNames[watchActionTypeSelect] || "Add Action"}
                  </LoadingButton>
                </Box>
              </>
            )}
          </DialogActions>
        </form>

        {checkedDevices.length === 1 && (
          <DevicePendingActionsTable
            checkedDevices={checkedDevices}
            multipleDevices={multipleDevices}
            userFriendlyTypeNames={userFriendlyTypeNames}
            setError={setError}
          />
        )}
      </Dialog>
    </>
  );
}

DeviceActionsForm.propTypes = {
  devices: PropTypes.array.isRequired,
  checkedRows: PropTypes.array.isRequired,
  multipleDevices: PropTypes.bool.isRequired,
  setCheckedRows: PropTypes.func.isRequired,
  setTableReload: PropTypes.func.isRequired,
};

export default DeviceActionsForm;
